export const PROJECT_NAME = 'Layal Bhsas';
export const BASE_URL = 'https://api.layalbhsas.com';
export const WEBSITE_URL = 'https://layalbhsas.com';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = BASE_URL + '/storage/images/';
export const MAP_API_KEY = 'AIzaSyAIZAHqq0Gpw0yNcq6LgsQd9EAGpee5sMg';
export const export_url = BASE_URL + '/storage/';
export const VAPID_KEY =
  'BPu_l5pUSCvLwgYC1OsDwXt15-GwoL5me0rO4FAR64MB_dwVHgMBtBWC2-ROwumJBEMeqnJR6yJ2YOj-IqkDeN0';

export const API_KEY = 'AIzaSyBRejGfYWQH-YeGIUYUjv15C20ObUdzla0';
export const AUTH_DOMAIN = 'layal-bhsas.firebaseapp.com';
export const PROJECT_ID = 'layal-bhsas';
export const STORAGE_BUCKET = 'layal-bhsas.appspot.com';
export const MESSAGING_SENDER_ID = '269720104483';
export const APP_ID = '1:269720104483:web:c05de7480f1514453b0b96';
export const MEASUREMENT_ID = 'G-BGY4M9XYHC';
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
